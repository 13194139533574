/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import BackgroundImage from 'gatsby-background-image'

// CSS
import './PreviewContainer.scss'

// Third Party

const BlogErvaringHeader = ({ className, image, title, location, quote }) => {
  return (
    <div className={`preview-container ${className ? `${className}` : ``}`}>
      <div className="row position-relative">
        <div className="image-container blog-header-image-container col-12 col-lg-6">
          <BackgroundImage fluid={image} />
        </div>
        <div className="col-12 col-lg-6" />
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 d-flex" />
            <div className="col-12 blog-header-title-container align-content-start d-flex flex-wrap col-lg-6 py-3">
              <div className="color-background-light w-100 p-3 p-lg-0">
                <h2 className="w-100 font-size-xl font-weight-xl"><span dangerouslySetInnerHTML={{ __html: title }} /></h2>
                <p className="font-size-xm font-weight-xl m-0 color-text-contrast">{location}</p>
              </div>
              {quote && (
                <div className="mt-0 mt-lg-5">
                  <div className="mt-lg-5 mt-0 px-3 pb-3 px-lg-0 pb-lg-0 color-background-light">
                    <p className="font-weight-m color-text-main font-weight-xl m-0 align-self-end">
                      <b className="font-size-m color-text-contrast">&quot;</b>
                      {` ${quote} `}
                      <b className="font-size-m color-text-contrast">&quot;</b>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogErvaringHeader
