/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Img from 'gatsby-image'

// Images
import phone from 'img/bellen.svg'
import whiteArrow from 'img/white_arrow.svg'

// Components


// CSS
import './Specialist.scss'
import { ButtonAlt } from '../Elements/Elements'


// Third Party
// import _ from 'lodash'

const Specialist = ({ specialist, horizontal, className }) => (
  <div className={`specialist d-flex flex-wrap justify-content-center ${className ? `${className}` : ``}`}>
    {horizontal ? (
      <div className="row flex-wrap-reverse">
        <div className="col-12 col-lg-6 d-flex justify-content-center flex-wrap">
          <h2 className="px-4 py-3 w-100 text-center font-weight-xl font-size-xm" dangerouslySetInnerHTML={{ __html: specialist[0].acf.specialist_text }} />
          <div className="pb-3 d-flex justify-content-evenly align-items-center w-100">
            <img alt="" src={phone} />
            <a href={`tel:${specialist[0].acf.number}`} className="m-0 color-text-contrast font-size-sm font-weight-xl" dangerouslySetInnerHTML={{ __html: specialist[0].acf.number}} />
          </div>
          <ButtonAlt to="/contact" className="mb-4 d-flex justify-content-between align-items-center">
            <div>
              <p className="m-0">Plan uw gratis</p>
              <p className="m-0">adviesgesprek</p>
            </div>
            <img className="mb-0 pl-2" alt="arrow" src={whiteArrow} />
          </ButtonAlt>
        </div>
        <div className="col-12 col-lg-6 p-3 d-flex justify-content-center">
          <Img fluid={specialist[0].acf.image.localFile.childImageSharp.fluid} />
        </div>
      </div>
  ) : (
    <>
      <div className="w-100 p-3 d-flex justify-content-center">
        <Img fluid={specialist[0].acf.image.localFile.childImageSharp.fluid} />
      </div>
      <h2 className="px-4 py-2 w-100 text-center font-weight-xl font-size-xm" dangerouslySetInnerHTML={{ __html: specialist[0].acf.specialist_text }} />
      <div className="pb-3 px-0 px-lg-5 d-flex justify-content-evenly align-items-center w-100">
        <img alt="" src={phone} />
        <a href={`tel:${specialist[0].acf.number}`} className="m-0 color-text-contrast font-size-sm font-weight-xl" dangerouslySetInnerHTML={{ __html: specialist[0].acf.number}} />
      </div>
      <ButtonAlt to="/contact" className="mb-3 d-flex justify-content-between align-items-center">
        <div>
          <p className="m-0">Plan uw gratis</p>
          <p className="m-0">adviesgesprek</p>
        </div>
        <img className="mb-0 pl-2" alt="arrow" src={whiteArrow} />
      </ButtonAlt>
    </>
  )}
  </div>
)


export default Specialist
