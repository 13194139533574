/* eslint-disable consistent-return */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'

// CSS
import 'styles/Blog.scss';

// Components
import BlogErvaringHeader from 'components/PreviewContainer/BlogErvaringHeader'
import Specialist from 'components/Specialist'

// Third Party
import parse from 'html-react-parser'
import CustomLink from '../components/CustomLink'

export const VacatureTemplate = (
  { 
    title,
    blogImage,
    content,
    specialist,
    h1Tag
  }) => {

  const location = ''
  
  return (
    <section className="section blog-detail">
      <h1 className="d-none">{parse(h1Tag)}</h1>
      <div className="blog-detail-thin-line d-lg-block d-none color-background-contrast" />
      <BlogErvaringHeader className="pb-5 pb-lg-0" image={blogImage} title={title} location={location} />
      <div className="blog-detail-thin-line d-lg-block d-none color-background-secondary" />
      <div className="container blog-container">
        <div className="row">
          <div className="col-12 pt-3 pb-2 back-to-blog"> 
            <CustomLink to="/vacatures" className="font-weight-xl font-size-sm">&gt; Terug naar vacatureoverzicht</CustomLink>
          </div>
          <div className="col-12 col-lg-8">
            <div className="pt-2 pb-5 blog-detail-content">
              {parse(content, {
                replace: (domNode) => {
                  if (domNode.name === 'a') {
                    if (domNode.attribs.href.indexOf('www.') !== -1) {
                      // Do nothing, the link is external
                    } else {
                      return <CustomLink to={domNode.attribs.href}>{domNode.children[0].data}</CustomLink>
                    }
                  }
                }
              })}
            </div>
          </div>
          <div className="col-12 col-lg-4 pt-2 d-flex justify-content-center justify-content-lg-end">
            {/* <Specialist specialist={specialist} /> */}
          </div>
        </div>

        <div className="empty-space-100" />
      </div>
    </section>
  )
}

const Vacature = ({ data }) => {

  const { wordpressPost: vacature } = data

  return (
    <Layout>
      {vacature.acf.blog_content.image.localFile.childImageSharp.fixed.src ? (
        <SEO yoast={{ meta: vacature.yoast_meta }} pathname={`/vacatures/${vacature.slug}`} image={vacature.acf.blog_content.image.localFile.childImageSharp.fixed.src} article />
      ) : (
        <SEO yoast={{ meta: vacature.yoast_meta }} pathname={`/vacatures/${vacature.slug}`} article />
      )}
      <VacatureTemplate
        title={vacature.title}
        blogImage={vacature.acf.blog_content.image.localFile.childImageSharp.fluid}
        content={vacature.acf.blog_content.story}
        specialist={vacature.acf.blog_content.specialist}
        h1Tag={vacature.acf.blog_content.h1_tag}
      />
    </Layout>
  )
}

export default Vacature

export const pageQuery = graphql`
  fragment VacaturePostFields on wordpress__post {
    id
    slug
  }
  query DetailVacatureQuery($id: String!) {
    wordpressPost(id: {eq: $id}) {
      title
      slug
      yoast_meta {
        content
        name
        property
      }
      acf {
        blog_content {
          small_introduction
          story
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                fixed(width: 1024, toFormat: JPG) {
                  src
                }
              }
            }
          }
          specialist {
            title
            slug
            acf {
              number
              specialist_text
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 250) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          h1_tag
        }
      }
    }
  }
`
